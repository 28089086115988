import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Rdv.scss'
import { DateTime } from "luxon";
import _ from 'lodash'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Image from 'components/Image';
import ExpoMini from 'components/Exposition/ExpoMini';
import ResidenceMini from 'components/Residence/ResidenceMini';
import AcInstanceTile from 'components/Ac/AcInstanceTile';
import Utils from 'utils/Utils';
import Hidden from '@material-ui/core/Hidden';
import Biographie from 'components/Artiste/Biographie';
import Mod from 'components/Mod';
import { useParams } from "react-router-dom";
import Ressources from 'components/Ressources';
import Partenaires from 'components/Partenaires';
import Share from 'components/Share';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

export const datesRdv=(rdv)=>[
  {titre:'',date:rdv.date,time_from:rdv.time_from,time_to:rdv.time_to},
  ...rdv.repetitions
];
export const parseTime=(evt,short=false)=>{
  const { time_from, time_to }=evt;
  if ( time_from && time_to ) {
    const tf=DateTime.fromISO(time_from);
    const tt=DateTime.fromISO(time_to);
    if (short) {
      const ftf=tf.toFormat("H:mm");
      const ftt=tt.toFormat("H:mm");
      return `${ftf}-${ftt}`;
    } else {
      const ftf=tf.toFormat("m")==="0" ? tf.toFormat("H'h'") : tf.toFormat("H'h'mm");
      const ftt=tt.toFormat("m")==="0" ? tt.toFormat("H'h'") : tt.toFormat("H'h'mm");
      return `de ${ftf} à ${ftt}`;
    }
  };
  if ( time_from ) {
    const tf=DateTime.fromISO(time_from);
    if (short) {
      const ftf=tf.toFormat("H:mm");
      return ftf;
    } else {
      const ftf=tf.toFormat("m")==="0" ? tf.toFormat("H'h'") : tf.toFormat("H'h'mm");
      return `à ${ftf}`;
    }
  };
  return '';
};
export const parseYear=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("y")===DateTime.now().toFormat("y") ? "" : df.toFormat("y");
  return fdf;
};
export const parseMonth=(evt,lang)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.setLocale(lang).toFormat("LLLL");
  return fdf;
};
export const parseDay=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("d");
  return fdf;
};
export const parseDates=(rdv,lang)=>{
  const dates=[...datesRdv(rdv)].sort((a,b)=>a.date-b.date);
  let res=_.groupBy(dates,(o)=>o.titre || '');
  Object.keys(res).forEach((titre) => {
      res[titre]=_.groupBy(res[titre],(evt)=>parseTime(evt));
      Object.keys(res[titre]).forEach((horaire)=>{
          res[titre][horaire]=_.groupBy(res[titre][horaire],(evt)=>parseYear(evt));
          Object.keys(res[titre][horaire]).forEach((year)=>{
              res[titre][horaire][year]=_.groupBy(res[titre][horaire][year],(evt)=>parseMonth(evt,lang));
              Object.keys(res[titre][horaire][year]).forEach((month)=>{
                    res[titre][horaire][year][month].forEach((item, i) => {
                        res[titre][horaire][year][month][i]=parseDay(item);
                    });
              });
          });
      });
  });
  return res;
};
export const displayDates=(dates,t)=>{
  return <>
  {Object.keys(dates).map((titre,it) => <React.Fragment key={it}>
      {titre && <div className="rdv-evt-titre">{titre} :</div>}
      <div className="rdv-evt-horaires">
      {Object.keys(dates[titre]).sort().map((horaire,ih)=><React.Fragment key={ih}>
          {ih>0 && <br />}
          {Object.keys(dates[titre][horaire]).sort((a,b)=>parseInt(a || 0)-parseInt(b || 0)).map((year,iy)=><React.Fragment key={iy}>
              {iy>0 && ', '}
              {Object.keys(dates[titre][horaire][year]).sort().map((month,im)=><React.Fragment key={im}>
                    {im>0 && ', '}
                    {dates[titre][horaire][year][month].sort((a,b)=>parseInt(a)-parseInt(b)).map((day, id) =>
                        <React.Fragment key={id}>{id>0 && (id===dates[titre][horaire][year][month].length-1 ? ` ${t('et')} `:', ')}{day}</React.Fragment>
                    )}
                    {month && ' '+month}
              </React.Fragment>)}
              {year && ' '+year}
          </React.Fragment>)}
          {horaire && ' '+horaire}
      </React.Fragment>)}
      </div>
  </React.Fragment>)}
  </>;
};

function Rdv() {
  const { slug } = useParams();
  const { t, lang, translate, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.rdv,{
    variables: { slug },
  });
  const uuid='rdv/'+slug;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let rdv=data ? data.rdvs[0] : null;
  useEffect(()=>{
    if (rdv && rdv.title) document.title=rdv.title
  },[rdv]);
  return rdv ? <div className="rdv">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div className="spacer5"></div>
          <div className="rdv-visuel">
            <Image credits file={rdv.visuel} thumbnailKey={"carre-small-nocrop"} background={rdv.visuel_background || 'white'}/>
          </div>
          <Hidden xsDown>
          {rdv.expositions.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('expositions')}</div>
            {rdv.expositions.filter((o)=>Boolean(o.expositions_id)).map(({expositions_id:expo})=><ExpoMini key={expo.id} expo={expo} mini={true}/>)}
            <div className="spacer"/>
          </div>}
          {rdv.residences.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('residences')}</div>
            {rdv.residences.filter((o)=>Boolean(o.residences_id)).map(({residences_id:residence})=><ResidenceMini key={residence.id} residence={residence} mini={true}/>)}
            <div className="spacer"/>
          </div>}
          {rdv.ac_instances.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('eac')}</div>
            {rdv.ac_instances.filter((o)=>Boolean(o.ac_instances_id)).map(({ac_instances_id:acInstance})=><AcInstanceTile size='mini' key={acInstance.id} acInstance={acInstance} mini={true}/>)}
          </div>}
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className="spacer5"></div>
          <div className="rdv-type">{rdv.types.map((type,i)=><span key={i}>{i>0 && ', '}{translate(type.types_id,'name')}</span>)}</div>
          <div className="rdv-titre">
            {rdv.title}
          </div>
          <div className="rdv-artiste">{Utils.renderArtistes(rdv,true,false).value}</div>
          <div className="rdv-dates">
              {displayDates(parseDates(rdv,lang),t)}
          </div>
          <div className="rdv-lieu">{rdv.lieu}</div>
          <div className="rdv-ville">{rdv.ville}</div>
          <div className="rdv-texte select">
            {Utils.parseWithLinks(translate(rdv,'text'))}
          </div>
          { rdv.diaporama && rdv.diaporama.length>0 && <div className="rdv-diaporama">
            {
            rdv.diaporama && rdv.diaporama.length>0 ?
              <Swiper
              speed={800}
              loop
              autoplay={{
                delay: 3000,
              }}
              enabled={rdv.diaporama.length>1}
              navigation={rdv.diaporama.length>1}
              >
                {rdv.diaporama.map((diap,i)=>{
                  const img=diap.directus_files_id;
                  return <SwiperSlide key={img.id}>
                    <Image credits file={img} thumbnailKey={"16-9"} ratio={0.5625}/>
                  </SwiperSlide>;
                })}
              </Swiper>
              : ''
            }
          </div>}
          <Share/>
          {rdv.artistes.map((artiste)=>artiste.artistes_id && artiste.artistes_id.bio ? <Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/> : null)}
          <Ressources item={rdv}/>
          <Partenaires item={rdv}/>
          <Hidden smUp>
          {rdv.expositions.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('expositions')}</div>
            {rdv.expositions.filter((o)=>Boolean(o.expositions_id)).map(({expositions_id:expo})=><ExpoMini key={expo.id} expo={expo} mini={true}/>)}
            <div className="spacer"/>
          </div>}
          {rdv.residences.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('residences')}</div>
            {rdv.residences.filter((o)=>Boolean(o.residences_id)).map(({residences_id:residence})=><ResidenceMini key={residence.id} residence={residence} mini={true}/>)}
            <div className="spacer"/>
          </div>}
          {rdv.ac_instances.length>0 && <div className="cartouche-infos">
            <div className="type-billet">{t('eac')}</div>
            {rdv.ac_instances.filter((o)=>Boolean(o.ac_instances_id)).map(({ac_instances_id:acInstance})=><AcInstanceTile size='mini' key={acInstance.id} acInstance={acInstance} mini={true}/>)}
          </div>}
          </Hidden>
        </Grid>
      </Grid>
      <Mod url={rdv.__typename+'/'+rdv.id} refresh={refetch}/>
    </div> : '';
}
export default Rdv;
